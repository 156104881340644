// src/App.js
import React from 'react';
import { Helmet } from 'react-helmet';
// import Wish from './components/Wish';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Career from './components/career';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Other from './components/Other';
// import Testimonials from './components/testimonials';
const App = () => {
    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="keywords" content="website, web development, web designing, web developer, web development services, static website, dynamic website, responsive website, fully responsive website, ecommerce website, html, css, javascript, react, nodejs, app development, app development services, android application, flutter, flutter developer, android, ios, apps developer, app developer, digital, marketing, digital marketing, digital marketer, digital marketing expert, digital marketing services, best digital marketing agency, social media marketing, online marketing, content marketing seo, search engine optimization, seo expert, seo services, data, data entry, data entry services, software, software development, software developer, software development services, IT consulting and IT services, IT company, R K Software Solutions, rk software, rk soft, rk softsol, rk software solutions, rksoftwaresolutions, rksoftwaresolutions.net, www.rksoftwaresolutions.net, http://www.rksoftwaresolutions.net, rksoftwaresolutions, rk solutions software, rksolutionsoftware, best IT company, best software company" />
                <meta name="robots" content="INDEX, FOLLOW"/>
                <meta name="title" content="R K Software Solutions" />
                <meta name="description" content="R K SOFTWARE SOLUTIONS is an IT services provider company dedicated to delivering cutting-edge digital solutions. Our services include web development, application development, digital marketing, and data entry services. With a team of skilled professionals, we strive to provide innovative and efficient solutions to meet our client's needs." />
                <link rel="icon" href="%PUBLIC_URL%/RK_logo_icon.png"></link>
            </Helmet>
            <Router>
                <Navbar />
                {/* <Wish/> */}
                <Switch>
                    <Route exact path="/">
                        <Home />
                        {/* <About /> */}
                        <Other/>
                    </Route>
                    <Route exact path="/about">
                        <About />
                    </Route>
                    <Route exact path="/services">
                        <Services />
                    </Route>
                    <Route exact path="/career">
                        <Career />
                    </Route>
                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                </Switch>
                <Footer />
            </Router>
        </>
    );
};

export default App;
